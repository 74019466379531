// we will need to clean secondary blue and dark blue. Keep secondray blue and replace all the other with it
// $gutter-width:		 1.0rem !default;
//

// Colors
// ==========================================================================

$sculpteo-blue: #3e8ddd;
$primary-blue: #3e8ddd; //#49E; //hsl(210, 83%, 60%)
$primary-blue-lighter: #85c7f7;
$primary-blue-darker: #1f66ad;
$secondary-blue: #044b92;
$secondary-lighter: #1f66ad;
$secondary-darker: #113669;
$grey-background: #f3f3f3; //#eeeeee  next color

$black: #111111;
$white: #ffffff;

$text-color: #353f4b; //text-color is the default color for text, almost black
$text-color-light: #ffffff; //text color light is the default color for text on dark background or colored background

$grey-darkest: #282828;
$grey-darker: #424242;
$grey-dark: #656565;
$grey: #929292; //grey medium
$grey-light: #cccccc;
$grey-lighter: #e2e2e2;
$grey-lightest: #eeeeee;
$grey-white: #f7f7f7;

$error-red: #f70600;
$infos-red: #eb383f;
$soft-red: #ef555b;

$green-lighter: #9be49b;
$green: #5fd35f;
$green-darker: #35c035;
$green-nextday-lighter: #bbd4b5;
// $green-nextday-lighter:     #92ba88;
$green-nextday: #79a86e;

//used in blog for ebook label, ebooks page and materials helps.
// still difficult to use that kind of color with our blue
$highlight-rare: #e67e22;

// New color 2019
// ==========================================================================
// ==========================================================================
// ==========================================================================

$primary-light: #85c7f7;
$primary: #3e8ddd;
$primary-dark: #1f66ad;

$secondary-light: #1f66ad;
$secondary: #044b92;
$secondary-dark: #113669;

$accent: #f7b359;
$accent-light: #f5d98c;
$accent-dark: #c98637;

$font-primary: #353f4b;
$font-secondary: #8a9096;
$font-secondary-darkbackground: #a8adb2;
$font-disabled: #ced1d6;

$background: #f3f3f3;

$basf-blue: #21a1d2;

$medical-green: #90d196;
$medical-green-light: #b8d8bb;
$medical-green-manage: #3aaa35;

$test-purple: #b590d1;
$test-purple-light: #c9b8d8;
$test-purple-manage: #9040d4;

// Colors print
// ==========================================================================

$dark-blue: $secondary-blue; //hsl(210, 95%, 29%)
$dark-blue-lighter: $secondary-lighter;
$dark-blue-darker: $secondary-darker;

$green-speed: #40ca35;

$notification-error-red: #dd2b27;
$notification-warn-yellow: #ecba23; //#e29d01
$notification-info-blue: $dark-blue;

// Colors dashboard
// ==========================================================================

$table-row-even: #f6f6f6;

$status-tag-new: $grey-lighter; //good
$status-tag-awaiting-payment: $soft-red; //good
$status-tag-in-process: #eeca56; //good
$status-tag-complete: #abdd70; //good
$status-tag-shipped: #abdd70; //same as complete
$status-tag-billed: #e98ad8; //good
$status-tag-cancelled: $grey;
$status-tag-blocked: $error-red;
$status-tag-all: $grey-darkest;

// Social media colors
// ==========================================================================

$facebook-color: #3b5998;
$youtube-color: #ff0000;
$googleplus-color: #dd4b39;
$linkedin-color: #0077b5;
$twitter-color: #1da1f2;

// Special user colors
// ==========================================================================
$staff-color: #3fe6be;
$superuser-color: #e53935;
$dev-color: #d81832;
$business-rewards-color: #f7b359;

// Fonts
// ==========================================================================

$primary-fontfamily: "Open Sans", sans-serif;
// use for making the rem easy to read
// as 14px = 1.4rem
// padding 20px = 2rem
$base-font-size: 10px;

$secondary-fontfamily: "brandon-grotesque", sans-serif;

// Media Query Ranges
// ==========================================================================

//use with min-width    MIN
$small-screen-up: 768px !default;
$medium-screen-up: 1024px !default;
$large-screen-up: 1201px !default;

//use with max-width    MAX
$small-screen: 767px !default;
$medium-screen: 1023px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 2rem !default;

// Layout
// ==========================================================================

//container max width
$max-container-width: 1380px;
$medium-container-width: 1080px;

$padding-size-small: 10px;
$padding-size: 20px;
$padding-size-big: 30px;

$promo-banner-height: 35px;

//effects

// $shadow-card: 0 4px 5px -2px rgba(0, 0, 0, 0.10), 0 0 0 1px rgba(0, 0, 0, 0.05);
// $shadow-card-hover: 0 10px 10px -4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);

$shadow-card: 0 4px 5px -2px rgba(0, 0, 0, 0.12);
$shadow-card-hover: 0 10px 10px -4px rgba(0, 0, 0, 0.18);
